//---------------------- Videos Import -------------------------------
import video1 from '../../assets/videos/ARENA CLUB NEGOTIN.mp4';
import video2 from '../../assets/videos/bella nella.mp4';
import video3 from '../../assets/videos/club infinity.mp4';
import video4 from '../../assets/videos/safari.mp4';
import video5 from '../../assets/videos/Snapinsta.app_video_AQMWigk79YknDQigAN57kZmytD9vALI06G6fuIRn0tekKlr8Br-ClAqW8LnYFEyqbjnxDLcaL2UwBg18ORcXiqWY.mp4';
import video6 from '../../assets/videos/Sunset fest.mp4';
import video7 from '../../assets/videos/wildnes fest 1.mp4';
import video8 from '../../assets/videos/wildnes fest.mp4';
import video9 from '../../assets/videos/Deki Safari 3.mp4';
import video10 from '../../assets/videos/Deki Safari 4.mp4';
import video11 from '../../assets/videos/Deki Safari 5.mp4';

//---------------------- Videos Export -------------------------------
const videoList = [

  video2,
  video3,
  video4,
  video5,
  video6,
  video7,
  video8,
  video9,
  video10,
  video11,
  video1,
];

export default videoList;
